//@ts-check
import cv, { Mat, Point, Rect } from "opencv-ts";

export function convertToGray(src: Mat) {
    let dst = new cv.Mat();
    cv.cvtColor(src,dst,cv.COLOR_RGBA2GRAY);
    return dst;
}

export function setRect(src:Mat,pt1:Point,pt2:Point){
    let color = new cv.Scalar(255,0,0);
    let thickness = 1;
    cv.rectangle(src,pt1,pt2,color,thickness,cv.LINE_4);
    return src;
}

export function convertToBinary(src:Mat,threshold:number){
    let dst = new cv.Mat();
    cv.threshold(src,dst,threshold,255,cv.THRESH_BINARY);
    return dst;
}

export function trimFromRect(src:Mat,rect:Rect){
    return src.roi(rect);
}

export function generateHistogram(src:Mat){
    // cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
    let srcVec = new cv.MatVector();
    srcVec.push_back(src);
    let accumulate = false;
    let channels = [0];
    let histSize = [256];
    let ranges = [0, 255];
    let hist = new cv.Mat();
    let mask = new cv.Mat();
    let color = new cv.Scalar(255, 255, 255);
    let scale = 2;
    // You can try more different parameters
    cv.calcHist(srcVec, channels, mask, hist, histSize, ranges, accumulate);
    let result = cv.minMaxLoc(hist, mask);
    let max = result.maxVal;
    let dst = new cv.Mat.zeros(src.rows, histSize[0] * scale,
                               cv.CV_8UC3);
    // draw histogram
    for (let i = 0; i < histSize[0]; i++) {
        let binVal = hist.data32F[i] * src.rows / max;
        let point1 = new cv.Point(i * scale, src.rows - 1);
        let point2 = new cv.Point((i + 1) * scale - 1, src.rows - binVal);
        cv.rectangle(dst, point1, point2, color, cv.FILLED);
    }
    return dst;
}
