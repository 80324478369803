import React from "react";

type NumberFromProps = {
    nu: number,
    setNu:(nu:number)=>void,
    max:number,
    step:number
}

export default function NumberForm(props:NumberFromProps) {
    const updateNu = (nu:number) => {props.setNu(nu);}
    return (
        <>
            <input type="range" value={props.nu} onChange={(e) => updateNu(Number(e.target.value))} min="0" max={props.max} step={props.step}/>
            <input type="number" value={props.nu} onChange={(e) => updateNu(Number(e.target.value))} />
        </>
    );
}

