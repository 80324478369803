import cv, { Mat, Point, Rect } from "opencv-ts";
import { convertToBinary, convertToGray, setRect, trimFromRect, generateHistogram } from "./image";
import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import Form from "./form";


export default function App() {
    let [pts, setPts] = useState({ x: 0, y: 0, width: 100, height: 100, threshold: 128 });
    let [x, setX] = useState(0);
    let [imgsrc, setImgsrc] = useState("");
    let [grabPoint, setGrabpoint] = useState({ x: 0, y: 0 });

    let imgElement = document.getElementById('imageSrc') as HTMLImageElement;

    let [isDragging,setIsDragging] = useState(false);
    let [isGrabing,setIsGrabing] = useState(false);

    function updateForm(){convert()}

    return (
        <>
            x: <Form nu={pts.x} max={1920} step={1} setNu={(nu) => { setPts((prev) => { return { ...prev, x: nu } }); updateForm(); }} /> <br />
            y: <Form nu={pts.y} max={1080} step={1} setNu={(nu) => { setPts((prev) => { return { ...prev, y: nu } }); updateForm(); }} /> <br />
            width: <Form nu={pts.width} max={100} step={1} setNu={(nu) => { setPts((prev) => { return { ...prev, width: nu } }); updateForm(); }} /> <br />
            height: <Form nu={pts.height} max={100} step={1} setNu={(nu) => { setPts((prev) => { return { ...prev, height: nu } }); updateForm(); }} /> <br />
            threshold: <Form nu={pts.threshold} max={255} step={1} setNu={(nu) => { setPts((prev) => { return { ...prev, threshold: nu } }); updateForm(); }} /> <br />
            imageSrc <input type="file" id="fileInput" name="file" onChange={(e) => {
                const target = e.target as HTMLInputElement;
                const file = (target.files as FileList)[0];
                setImgsrc(URL.createObjectURL(file));
                convert();
            }} /> <br />
            grabPoint: <input type="text" name="" id="" value={grabPoint.x + "," + grabPoint.y} size={100} /> <br />
            <input type="text" name="" id="alltext" value={
                (() => {
                    let text = "";
                    for (const key of Object.keys(pts)){
                        text += key + ":" + pts[key] + ",";
                    }
                    return text;
                })()} size={100} /> <br />
            <input type="button" value="テキスト読み込み" id="readtext" /><br />
            <input type="button" value="画像読み込み" id="readimg" /><br />
            <input type="button" value="再描画" id="redraw" onClick={convert} /><br />


            <div className="inputoutput">
                <canvas id="canvasOutput" onMouseDown={(e) => {
                    let target = e.target as HTMLCanvasElement;
                    let clientRect = target.getBoundingClientRect();
                    let x = e.clientX - clientRect.left;
                    let y = e.clientY - clientRect.top;
                    if (isRectCorner(x, y)) {
                        setIsGrabing(true);
                        setGrabpoint({ x, y });
                    }
                    else if (isIncludeRect(x, y)) {
                        setIsDragging(true)
                        let box = getRect();
                        setGrabpoint({ x: box.x - x, y: box.y - y });
                    }

                }} onMouseMove={(e) => {
                    if (isGrabing) {
                        let target = e.target as HTMLCanvasElement;
                        let clientRect = target.getBoundingClientRect();
                        let x = e.clientX - clientRect.left;
                        let y = e.clientY - clientRect.top;
                        let src = cv.imread(imgElement);
                        //使う値定義
                        let pt1 = new cv.Point(pts.x, pts.y);
                        let pt2 = new cv.Point(pts.x + pts.width + x - grabPoint.x, pts.y + pts.height + y - grabPoint.y);
                        //グレースケール化&矩形追加画像
                        let dst = setRect(convertToGray(src), pt1, pt2);
                        cv.imshow('canvasOutput', dst);
                        src.delete();
                        dst.delete();
                    }
                    else if (isDragging) {
                        try {
                            let target = e.target as HTMLCanvasElement;
                            let clientRect = target.getBoundingClientRect();
                            let x = e.clientX - clientRect.left;
                            let y = e.clientY - clientRect.top;
                            drawRect(x, y);
                            console.log("moving");
                            console.log({ x, y });
                        } catch (error) {
                            console.error(error);
                            setIsDragging(false);
                            setGrabpoint({ x: 0, y: 0 });
                        }
                    }
                }} onMouseUp={(e) => {
                    if (isGrabing) {
                        let target = e.target as HTMLCanvasElement;
                        let clientRect = target.getBoundingClientRect();
                        let x = e.clientX - clientRect.left;
                        let y = e.clientY - clientRect.top;
                        setPts((prev) => {
                            return {
                                ...prev,
                                width: prev.width + x - grabPoint.x,
                                height: prev.height + y - grabPoint.y
                            }
                        })
                        setIsGrabing(false);
                        setGrabpoint({ x: 0, y: 0 });
                    }
                    if (isDragging) {
                        let target = e.target as HTMLCanvasElement;
                        let clientRect = target.getBoundingClientRect();
                        let x = e.clientX - clientRect.left;
                        let y = e.clientY - clientRect.top;
                        drawRect(x, y);
                        setIsDragging(false)
                        setGrabpoint({ x: 0, y: 0 });
                        convert();
                    }
                }}></canvas>
                <div className="caption">canvasOutput</div>
            </div>
            <div className="inputoutput">
                <canvas id="trimed"></canvas> | <canvas id="histogram"></canvas>
            </div>
            <div className="inputoutput">
                <img id="imageSrc" alt="No Image" src={imgsrc} />
            </div>
        </>
    );

    function getRect() {
        return pts;
    }

    function isIncludeRect(x: number, y: number) {
        let rect = getRect();
        return rect.x < x && x < rect.x + rect.width && rect.y < y && y < rect.y + rect.height;
    }

    function isRectCorner(x: number, y: number) {
        let rect = getRect();
        let corner = { x: rect.x + rect.width, y: rect.y + rect.height };
        return corner.x - 5 < x && x < corner.x + 5 && corner.y - 5 < y && y < corner.y + 5;
    }

    function drawRect(x: number, y: number) {
        let src = cv.imread(imgElement);
        //使う値定義

        let rect = getRect();
        let pt1 = new cv.Point(grabPoint.x + x, grabPoint.y + y);
        let pt2 = new cv.Point(grabPoint.x + rect.width + x, grabPoint.y + rect.height + y);
        setPts((prev) => {
            return {
                ...prev,
                x: grabPoint.x + x,
                y: grabPoint.y + y
            }
        })
        //グレースケール化&矩形追加画像
        let dst = setRect(convertToGray(src), pt1, pt2);
        cv.imshow('canvasOutput', dst);
        src.delete();
        dst.delete();
    }


    function convert() {
        let src = cv.imread(imgElement);
        //使う値定義

        let pt1 = new cv.Point(pts.x, pts.y);
        let pt2 = new cv.Point(pts.x + pts.width, pts.y + pts.height);
        let threshold = pts.threshold;

        //グレースケール化&矩形追加画像

        let dst = setRect(convertToGray(src), pt1, pt2);
        cv.imshow('canvasOutput', dst);

        //binary化&trimed画像
        let rect = new cv.Rect(pts.x,pts.y,pts.width,pts.height);
        let trimed = trimFromRect(convertToBinary(convertToGray(src), threshold), rect);
        cv.imshow("trimed", trimed);

        let histogram = generateHistogram(convertToGray(trimFromRect(src, rect)));
        cv.imshow("histogram", histogram);

        //あとしまつ
        src.delete();
        dst.delete();
        trimed.delete();
        histogram.delete();
    };



}

const container = document.getElementById("root");
const root = ReactDOM.render(<App />, container);


